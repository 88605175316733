<template>
  <div class="mt-5">
    <v-row no-gutters>
      <v-col cols="12" md="4" sm="4" lg="4" xl="4">
        <v-toolbar dense elevation="0">
          <div class="mr-5"><h3>Administrator</h3></div>
        </v-toolbar>
      </v-col>
      <v-spacer />
      <v-col cols="12" md="4" sm="4" lg="4" xl="4">
        <v-toolbar dense class="elevation-0">
          <v-text-field
            v-model="search"
            label="search"
            class="mr-2 mt-5"
            dense
            append-icon="mdi-magnify"
          ></v-text-field>
          <v-tooltip bottom right color="primary">
            <template v-slot:activator="{ on }">
              <v-btn
                @click="addAdminDialog = true"
                small
                v-on="on"
                class="mr-2 gradient-bg white--text"
              >
                <v-icon dense>mdi-plus</v-icon>
              </v-btn>
            </template>
            <span class="white--text">Add Administrator</span>
          </v-tooltip>
        </v-toolbar>
      </v-col>
    </v-row>
    <v-row no-gutters class="mx-4">
      <v-col cols="12" md="12" sm="12" lg="12" xl="12">
        <v-data-table
          :headers="AdminData"
          :items="AdminItems"
          dense
          class="overflow-y-auto overflow"
          :fixed-header="fixed"
          :height="height"
          :search="search"
          :loading="isLoading"
        >
          <template v-slot:[`no-data`]>
            <v-alert class="gradient-bg2 white--text">
              No Admins added yet
            </v-alert>
          </template>
          <template v-slot:no-results>
            <v-alert :value="true" class="gradient-bg2 white--text">
              No records found!
            </v-alert>
          </template>
          <template v-slot:[`item.Actions`]="{ item }">
            <v-tooltip bottom color="primary">
              <template v-slot:activator="{ on }">
                <a
                  v-if="$store.getters.get_useremail != item.user_email_id"
                  @click="delete_admins_details(item)"
                >
                  <v-icon v-on="on" small color="red">mdi-delete </v-icon>
                </a>
              </template>
              <span class="white--text">Delete this Admin?</span>
            </v-tooltip>
          </template>
        </v-data-table>
      </v-col>
    </v-row>
    <Overlay :overlay="overlay" />
    <SnackBar :SnackBarComponent="SnackBarComponent"/>
    <DeleteDialog
      :confDeleteDialog="confDeleteDialog"
      @close_conf_delete_dialog="confDeleteDialog = false"
      :deleteText="deleteText"
      :deleteIcon="deleteIcon"
      :deleteColor="deleteColor"
      @success_conf_delete="deleteSuccessAction"
      :deleteLoading="deleteLoading"
    />
    <CreateAdminsDialog
      :addAdminDialog="addAdminDialog"
      @get_admins="get_admins_list"
      @close_dialog="addAdminDialog = false"
    />
  </div>
</template>

<script>
import Overlay from "@/components/Overlay.vue";
import SnackBar from "@/components/SnackBar.vue";
import { API, graphqlOperation } from "aws-amplify";
import { GetAllUsers } from "@/graphql/queries.js";
import DeleteDialog from "@/components/Dialogs/DeleteDialog.vue";
import { DeleteUsers } from "@/graphql/mutations.js";
import CreateAdminsDialog from "@/components/Dialogs/Admins/CreateAdminsDialog.vue";

export default {
  components: {
    Overlay,
    SnackBar,
    DeleteDialog,
    CreateAdminsDialog,
  },
  data: () => ({
    addAdminDialog: false,
    deleteLoading: false,
    deleteColor: "",
    deleteText: "",
    deleteIcon: "",
    confDeleteDialog: false,
    AdminData: [
      { text: "E-Mail", value: "user_email_id" },
      { text: "Actions", value: "Actions" },
    ],
    AdminItems: [],
    search: "",
    fixed: true,
    height: 0,
    isLoading: false,
    overlay: false,
    SnackBarComponent: {},
  }),
  mounted() {
    this.height = window.innerHeight - 190;
    this.get_admins_list();
  },
  methods: {
    async get_admins_list() {
      this.AdminItems = [];
      this.isLoading = true;
      this.overlay = true;
      try {
        let result = await API.graphql(
          graphqlOperation(GetAllUsers, {
            input: {},
          })
        );
        this.AdminItems = JSON.parse(result.data.GetAllUsers).Items;
        this.isLoading = false;
        this.overlay = false;
      } catch (err) {
        console.log("error", err);
        this.isLoading = false;
        this.overlay = false;
      }
    },
    deleteSuccessAction() {
      this.deleteLoading = true;
      this.delete_admins(this.deleteUserItem);
    },
    delete_admins_details(item) {
      this.deleteUserItem = item;
      this.confDeleteDialog = true;
      this.deleteText = `Are you sure you want to Delete the admin ${item.user_email_id} ?`;
      this.deleteIcon = "mdi-account-off";
      this.deleteColor = "red";
    },
    async delete_admins(user) {
      this.deleteLoading = true;
      try {
        var inputParams = {
          user_email_id: user.user_email_id,
        };
        for (const [key, value] of Object.entries(inputParams)) {
          if (value == "") delete inputParams[key];
        }
        await API.graphql(
          graphqlOperation(DeleteUsers, {
            input: inputParams,
          })
        ).then((res) => {
          this.get_admins_list();
          this.SnackBarComponent = {
            SnackbarVmodel: true,
            SnackbarColor: "green",
            Top: true,
            SnackbarText: JSON.parse(res.data.DeleteUsers).message,
          };
          this.deleteLoading = false;
          this.confDeleteDialog = false;
        });
      } catch (err) {
        console.log("err", err);
        this.SnackBarComponent = {
          SnackbarVmodel: true,
          SnackbarColor: "red",
          Top: true,
          SnackbarText: err.errors[0].message,
        };
        this.deleteLoading = false;
      }
    },
  },
};
</script>

<style>
</style>