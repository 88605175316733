<template>
  <div>
    <v-dialog v-model="addAdminDialog" persistent width="500px">
      <v-toolbar dense class="elevation-0 gradient-bg white--text">
        <v-toolbar-title>Create Admins</v-toolbar-title>
        <v-spacer />
        <v-btn @click="close_dialog()" text
          ><v-icon class="white--text">mdi-close</v-icon>
        </v-btn>
      </v-toolbar>
      <v-card class="overflow--hidden">
        <v-form ref="form">
          <v-row no-gutters>
            <v-col cols="12" md="12">
              <v-text-field
                v-model="email_id"
                :rules="email_id_rules"
                dense
                outlined
                class="mx-3 mt-3"
              ></v-text-field>
            </v-col>
          </v-row>
        </v-form>
        <v-card-text align="center">
          <v-btn
            :loading="Loading"
            @click="validate_add_admins()"
            class="gradient-bg white--text"
            >Create</v-btn
          >
        </v-card-text>
      </v-card>
      <SnackBar :SnackBarComponent="SnackBarComponent" />
    </v-dialog>
  </div>
</template>

<script>
import SnackBar from "@/components/SnackBar.vue";
import { API, graphqlOperation } from "aws-amplify";
import { signup_userV2 } from "@/graphql/mutations.js";

export default {
  props: {
    addAdminDialog: Boolean,
  },
  components: {
    SnackBar,
  },
  data: () => ({
    email_id: "",
    Loading: false,
    email_id_rules: [
      (v) => !v || /.+@.+\..+/.test(v) || "E-mail must be valid",
    ],
    SnackBarComponent: {},
  }),
  methods: {
    validate_add_admins() {
      if (this.$refs.form.validate()) {
        this.add_admins();
      } else {
        this.SnackBarComponent = {
          SnackbarVmodel: true,
          SnackbarColor: "red",
          Top: true,
          SnackbarText: "Enter Valid Inputs",
        };
      }
    },
    async add_admins() {
      this.Loading = true;
      try {
        var inputParams = {
          user_email_id: this.email_id.toLowerCase(),
        };
        for (const [key, value] of Object.entries(inputParams)) {
          if (value == "") delete inputParams[key];
        }
        await API.graphql(
          graphqlOperation(signup_userV2, {
            input: inputParams,
          })
        ).then((res) => {
          this.SnackBarComponent = {
            SnackbarVmodel: true,
            SnackbarColor: "green",
            Top: true,
            SnackbarText: JSON.parse(res.data.signup_userV2).message,
          };
          this.$refs.form.reset();
          this.Loading = false;
          this.$emit("get_admins");
          this.close_dialog();
        });
      } catch (err) {
        console.log("err", err);
        this.SnackBarComponent = {
          SnackbarVmodel: true,
          SnackbarColor: "red",
          Top: true,
          SnackbarText: err.errors[0].message,
        };
        this.Loading = false;
      }
    },
    close_dialog() {
      this.$emit("close_dialog");
    },
  },
};
</script>

<style>
</style>